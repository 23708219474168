@font-face {
  font-family: 'Arial Black';
  src: url('../fonts/Arial-Black.eot?#iefix') format('embedded-opentype'), 
  url('../fonts/Arial-Black.woff') format('woff'),
  url('../fonts/Arial-Black.ttf')  format('truetype'),
  url('../fonts/Arial-Black.svg#Arial-Black') format('svg');
  font-weight: normal;
}

/*-----------------------------AOS--------------------------*/

html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
  opacity: 0 !important;
}

html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1 !important;
  -webkit-transform: none !important;
  transform: none !important;
}

html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1 !important;
  -webkit-transform: none !important;
  transform: none !important;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s !important;
}
[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s !important;
}
html:not(.no-js) [data-aos="fade-left"] {
  -webkit-transform: translate3d(100px, 0, 0) !important;
  transform: translate3d(100px, 0, 0) !important;
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease !important;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s !important;
}
html:not(.no-js) [data-aos="fade-left"] {
  -webkit-transform: translate3d(100px, 0, 0) !important;
  transform: translate3d(100px, 0, 0) !important;
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease !important;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s !important;
}

/*-----------------------------RESET--------------------------*/

html{
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
  margin: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
a,
ul[class],
ol[class],
li,
button,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-family: 'Arial Black', "Arial Bold", Gadget, sans-serif;
  list-style: none;
  line-height: 1.5;
}

/* Set core body defaults */
body {
  /*min-height: 100vh;*/
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active {
  color: #cc2936;
  transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}


/*-----------------------------FONTS-SIZES--------------------------*/

h1 {
  font-size: 42px;
}

h2 {
  font-size: 32px;
}

@media only screen and (max-width: 1366px) {
  h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }
}
/*-----------------------------MENU--------------------------*/

.header-container {
  top: 0;
  left: 0;
  z-index: 99;
  padding: 15px 35px;
  display: inline-block;
}

.header-container h1 {
  padding: 15px 15px 0;
  color: #cc2936;
  text-transform: uppercase;
}

.menu-container li .menu-item {
  font-size: 12px;
  color: black;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
}

.menu-container li {
  list-style-type: none;
  padding: 0 12.5px 15px;
}

.menu-container {
  position: relative;
  z-index: 999;
}

.menu {
  display: flex;
  align-items: center;
}

.menu li {
  transition: 0.5s;
  position: relative;
}

.menu-container .menu li:first-child {
  padding-left: 17px;
}

.header-container .menu .menu-item.dark {
  color: #fff;
}

.header-container .menu .menu-item:hover {
  color: #cc2936;
}

.top-bar {
  position: absolute;
  width: 100%;
  height: 0;
  background-color: #fff;
  -webkit-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header-container .links-c {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header-container .links-c a {
  font-size: 14px;
  line-height: 1;
}

.header-container .links-c::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #000000;
  bottom: -7.5px;
  left: 0;
  opacity: 0;
  -webkit-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0ms;
}

.menu-open,
.atelier-open {
  overflow-y: hidden;
}

.menu-open .header-container .links-c::before {
  opacity: 1;
  left: 50px;
  transition-delay: 100ms;
  -webkit-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 150ms;
}

.header-container .contacts-container {
  margin-bottom: 22px;
  margin-top: 25px;
}

.menu-open .top-bar {
  height: 330px;
  z-index: 999;
}

.atelier-open .top-bar {
  height: 375px;
  z-index: 999;
}

.menu #atelier-menu {
  display: flex;
  transition: transform 0.4s, color 100ms;
}

.menu #contact-menu {
  display: flex;
  transition: transform 0.4s, color 100ms;
}

.menu-open .menu #contact-menu,
.atelier-open .menu #atelier-menu {
  transform: translateX(30px);
  color: #cc2936;
  opacity: 1;
}

.atelier-open .menu #contact-menu {
  transform: translateX(30px);
}

.menu #contact-menu:before,
.menu #atelier-menu::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 20px;
  background: url("../img/menu-close.svg") center/contain no-repeat;
  opacity: 0;
  top: 3px;
  left: -25px;
  transform: rotate(-60deg);
  pointer-events: none;
}

.menu-open .menu #contact-menu:before,
.atelier-open #atelier-menu::before {
  opacity: 1;
  transition: all 0.4s 0.2s;
  transform: rotate(0deg);
  pointer-events: all;
}

.contact-menu-container .politica-c {
  display: flex;
  flex-direction: column;
}

.contact-menu-container a,
.contact-menu-container .link {
  padding-bottom: 10px;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0ms;
}

.menu-open .contact-menu-container a,
.menu-open .contact-menu-container .link {
  opacity: 1;
  z-index: 1;
  padding-left: 50px;
  transition-delay: 100ms;
  -webkit-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
  -moz-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
  -o-transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
  transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 50ms;
}

.contact-menu-container .politica-container .link {
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.menu-open .contact-menu-container .politica-container .link {
  padding-left: 45px;
  margin-left: 5px;
}

.menu-open .contact-menu-container .politica-container .link:hover{
  color: #cc2936;
}

.overlay-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.54);
  opacity: 0;
  z-index: 99;
  z-index: -1;
  -webkit-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0ms;
}

.menu-open .overlay-menu,
.atelier-open .overlay-menu {
  opacity: 1;
  z-index: 99;
  -webkit-transition: 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 100ms;
}

.atelier-open .overlay-menu {
  transition-delay: 0;
}

.menu-open .header-container .menu .menu-item:not(#contact-menu),
.atelier-open .header-container .menu .menu-item:not(#atelier-menu) {
  pointer-events: none;
}

.menu-open .top-bar.politic-open-conf,
.menu-open .top-bar.politic-open-cookies {
  height: auto;
  position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.menu-open .top-bar.politic-open-cookies {
  height: auto;
}

.menu-open .politics-main-container {
  max-width: 1500px;
}

.menu-open .politics-main-container,
.top-bar .atelier-container {
  padding: 25px 200px 10px 50px;
  opacity: 0;
  -webkit-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0ms;
}

.top-bar .atelier-container {
  padding: 10px 200px 10px 50px;
  position: relative;
  transform: translateY(-200px);
  margin-bottom: -100px;
}

.atelier-open .atelier-container {
  opacity: 1;
  padding: 10px 100px 10px 100px;
  -webkit-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 200ms;
}

.menu-open .politic-open-conf .politics-main-container {
  opacity: 1;
  padding: 25px 200px 10px 100px;
  -webkit-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 150ms;
}

.menu-open .politic-open-cookies .politics-main-container {
  opacity: 1;
  padding: 25px 200px 10px 100px;
  -webkit-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 150ms;
}

.menu-open .politic-open-conf .politica-container .politica-link-1,
.menu-open .politic-open-cookies .politica-container .politica-link-2 {
  transform: translateX(30px);
  color: #cc2936;
  position: relative;
  transition: all 0.4s;
}

.menu-open .politica-container .politica-link-1::before,
.menu-open .politica-container .politica-link-2::before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 15px;
  background: url("../img/menu-close.svg") center/contain no-repeat;
  opacity: 0;
  top: 1px;
  left: 20px;
  transition: all 0s;
  transform: rotate(-60deg);
}

.menu-open .politic-open-conf .politica-container .politica-link-1::before,
.menu-open .politic-open-cookies .politica-container .politica-link-2::before {
  opacity: 1;
  transition: all 0.4s 0.2s;
  transform: rotate(0);
}

.top-bar .politics-title {
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #000;
  font-size: 20px;
}

.top-bar .politics-content h3 {
  margin-top: 25px;
  font-size: 14px;
  text-transform: uppercase;
}

.header-container .menu .menu-item {
  color: #000;
  font-size: 18px;
  -webkit-transition: color 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: color 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: color 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: color 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
  position: relative;
}

.header-container .menu .menu-item.is-active {
  color: #cc2936;
}

.menu-open .header-container .menu .menu-item,
.atelier-open .header-container .menu .menu-item {
  color: #000;
  opacity: 0.3;
}

.menu-open .header-container .menu .menu-item.is-active,
.atelier-open .header-container .menu .menu-item.is-active {
  color: #cc2936;
  opacity: 0.3;
}

.menu-open .not-contacts::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: all;
  left: 0;
  top: 0;
}

  @media only screen and (max-device-width: 1280px){
    .header-container .menu .menu-item:active,
    .header-container .menu .menu-item:focus,
    .header-container .menu .menu-item:hover{
      color: #000;
  }

    .header-container .menu .menu-item.dark:active,
    .header-container .menu .menu-item.dark:focus,
    .header-container .menu .menu-item.dark:hover{
      color: #fff;
    }
   }

@media only screen and (max-width: 800px) {
  .header-container {
    padding: 15px 15px;
  }

  .menu-open .politics-main-container {
    padding: 25px 35px 10px 0px;
  }

  .menu-open .politic-open-conf .politics-main-container,
  .menu-open .politic-open-cookies .politics-main-container {
    padding: 25px 35px 10px 35px;
  }

  .header-container .menu .menu-item.is-active {
    color: #cc2936;
  }
}

@media only screen and (max-width: 601px) {
  .header-container .menu .menu-item.dark {
    color: #000;
  }

  .header-container .menu .menu-item:hover {
    color: #cc2936;
  }

  .header-container {
    padding: 0;
    width: 100%;
  }

  .header-container .menu-container {
    padding-top: 15px;
    z-index: auto;
  }

  .header-container h1 {
    position: relative;
    z-index: 999;
    display: inline-block;
  }

  .header-container .menu-container li {
    padding-left: 17px;
    -webkit-transition: 0ms;
    -moz-transition: 0ms;
    -o-transition: 0ms;
    transition: 0ms;
    opacity: 0;
    transition-delay: 0ms;
    transform: translateX(-15px);
  }

  .mobile-open .header-container .menu-container .menu li {
    -webkit-transition: 450ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: 450ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: 450ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: 450ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    opacity: 1;
    transform: translateX(0);
  }

  .mobile-open .header-container .menu-container .menu > li:nth-child(2) {
    transition-delay: 20ms;
  }

  .mobile-open .header-container .menu-container .menu > li:nth-child(3) {
    transition-delay: 40ms;
  }

  .mobile-open .header-container .menu-container .menu > li:nth-child(4) {
    transition-delay: 80ms;
  }

  .header-container .menu .menu-item {
    font-size: 20px;
  }

  .header-container .menu .menu-item:hover,
  .header-container .menu .menu-item.dark:hover {
    color: #000;
  }

  .atelier-open .menu #atelier-menu,
  .menu-open .menu #contact-menu {
    color: #cc2936;
  }

  .header-container .menu {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    align-items: flex-start;
  }

  .header-container .button_container {
    position: absolute;
    top: 44px;
    right: 15px;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 999;
    transition: opacity 0.25s ease;
  }
  .header-container .button_container.active .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #cc2936;
  }
  .header-container .button_container.active .middle {
    opacity: 0;
    background: #cc2936;
  }
  .header-container .button_container.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #cc2936;
  }
  .header-container .button_container span {
    background: #000;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
  }
  .header-container .button_container span:nth-of-type(2) {
    top: 11px;
  }
  .header-container .button_container span:nth-of-type(3) {
    top: 22px;
  }
  .mobile-open .top-bar,
  .menu-open .top-bar,
  .atelier-open .top-bar {
    height: 100%!important;
    z-index: 999;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .menu #contact-menu:before,
  .menu #atelier-menu::before {
    width: 14px;
    height: 18px;
    top: 6px;
  }

  .atelier-open .menu #contact-menu {
    transform: none;
  }

  .menu-open,
  .atelier-open {
    position: relative;
  }

  .top-bar .atelier-container {
    padding: 10px 35px 10px 0px;
    transform: translateY(-185px);
  }

  .atelier-open .atelier-container {
    padding: 10px 35px 10px 35px;
  }

  .atelier-container h1 {
    padding-bottom: 10px;
    height: 100% !important;
    line-height: normal;
  }

  .mobile-open .main-wrapper{
    overflow-y: hidden;
    max-height: 100vh;
  }

  .mobile-open .main-wrapper .top-bar{
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 480px) {
  .header-container .button_container span {
    height: 3px;
  }

  .header-container .button_container span:nth-of-type(2) {
    top: 8px;
  }

  .header-container .button_container span:nth-of-type(3) {
    top: 16px;
  }

  .header-container .button_container.active .bottom {
    transform: translateY(-5px) translateX(0) rotate(-45deg);
  }

  .header-container .menu .menu-item {
    font-size: 17px;
  }
}

@media only screen and (max-width: 375px) {
  .header-container h1 {
    padding: 15px 10px 0;
    font-size: 26px;
  }

  .header-container .button_container {
    top: 42px;
    right: 10px;
  }

  .mobile-open .header-container .button_container {
    top: 40px;
    right: 10px;
  }

}

@media only screen and (max-height: 550px){
  .header-container .menu .menu-item{
    font-size: 14px;
  }

  h1 {
    font-size: 32px;
    line-height: normal;
  }

  .top-bar .atelier-text{
    height: 0;
  }

  .atelier-open .top-bar .atelier-text{
    height: 100%;
  }
}

/*-----------------------------HOME--------------------------*/

#gallery-container {
  width: auto;
  position: relative;
  height: 100vh;
  overflow-y: hidden;
}

.single-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

#overlay-transition {
  position: fixed;
  height: 100%;
  width: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
}

#overlay-transition-2 {
  position: fixed;
  height: 100%;
  width: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.main-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 950px) {
  .details-open #overlay-transition,
  .details-open #overlay-transition-2 {
    z-index: 999;
  }
}

@media only screen and (max-width: 800px) {
  #gallery-container{
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}
/*-----------------------------PROJECTS NEW----------------------------*/

/*------MANSONRY GALERY--------*/

.project-galery {
  overflow: hidden;
}

.flexbox-galery {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flexbox-galery .item img {
  width: 100%;
  display: block;
  transition: all 0.8s;
}
.flexbox-galery .item .title {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 3%;
  font-size: 30px;
  bottom: 10px;
  font-size: 14px;
}

.flexbox-galery .item .title {
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  opacity: 0;
  color: #fff;
}

.flexbox-galery .item:hover .title {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.75s ease 0.8s;
  transition: all 0.75s ease 0.4s;
}

.flexbox-galery .item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.70) 0%,
    rgba(0, 0, 0, 0.50) 15%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0.1) 74%,
    rgba(0, 0, 0, 0) 100%
    );
  z-index: 0;
  opacity: 0;
  --webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease,
    -webkit-transform 0.5s ease;
}

.flexbox-galery .item:hover:before {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  --webkit-transition: opacity 1s ease, -webkit-transform 1s ease 0.25s;
  transition: opacity 1s ease, -webkit-transform 1s ease 0.25s;
  transition: opacity 1s ease, transform 1s ease 0.25s;
}

.flexbox-galery .item .title span{
  font-size: 11px;
  line-height: normal;
  margin-top: 3.5px;
}

.flexbox-galery .item .title span.local{
  opacity: 1;
  float: right;
  text-transform: uppercase;
}

.flexbox-galery .item .title span.subtitle{
  font-family: "Lato", sans-serif;
  font-weight: 400;
  opacity: 0.9;
  font-size: 13px;
}

@media (max-width: 667px) {
  .flexbox-galery {
    height: auto;
  }
  .flexbox-galery .item {
    width: 100%;
  }
}

.flexbox-galery-new .item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 3px solid #fff;
  position: relative;
}

.flexbox-galery-new .item1{
  background-image: url('../img/SEINE_DESIGN-hotel-paris.jpg');
  width: 100%;
  height: 700px;
}

.flexbox-galery-new .item2{
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_0.jpg');
  width: 50%;
  height: 500px;
  background-position: center bottom;
}

.flexbox-galery-new .item3{
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_2_outside_view_sergio_grazia.jpg');
  width: 50%;
  height: 500px;
}

.flexbox-galery-new .item4{
  background-image: url('../img/2018_DUCASSE_EN_SEINE_Seine_Design_1.jpg');
  background-position: right bottom;
  width: 66.6666%;
  height: 750px;
  background-size: 110%;
}

.flexbox-galery-new .item5{
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-001.jpg');
  width: 100%;
  height: 375px;
}

.flexbox-galery-new .item6{
  background-image: url('../img/2010_CRISTAL_II_Seine_Design_1.jpg');
  width: 100%;
  height: 375px;
}

.flexbox-galery-new .item7{
  background-image: url('../img/2015_EPINAY_SUR_SEINE_Seine Design_1_web.jpg');
  width: 50%;
  height: 600px;
  background-position: 100% 70%;
}

.flexbox-galery-new .item8{
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-07.jpg');
  width: 50%;
  height: 600px;
}

.flexbox-galery-new .item9{
  background-image: url('../img/2021_L-ARCHE_Seine-Design_7.jpg');
  width: 100%;
  height: 800px;
  background-position: 100% 70%;
}

.flexbox-galery-new .item10{
  background-image: url('../img/2008_AL-MINSAF_Seine_Design_1.jpg');
  width: 50%;
  height: 600px;
  background-position: 100% 70%;
}

.flexbox-galery-new .item11{
  background-image: url('../img/1999_CELESTIAL_Seine_Design_1.jpg');
  width: 50%;
  height: 600px;
}


.flexbox-galery-new .item12{
  background-image: url('../img/CHALET_AQUATIQUE-Versailles.jpg');
  width: 45%;
  height: 550px;
}

.flexbox-galery-new .item13{
  background-image: url('../img/NANDA_DEVI_Galerie_dArt-Paris.jpg');
  width: 100%;
  height: 275px;
  background-position: center bottom;
}

.flexbox-galery-new .item22{
  background-image: url('../img/DIAMANT_Bateau_croisère-Paris.jpg');
  width: 50%;
  height: 275px;
  background-position: center bottom;
}

.flexbox-galery-new .item23{
  background-image: url('../img/WEB_SeineDesign_musee-flottant-salvador-dali-2.jpg');
  width: 50%;
  height: 275px;
}

.flexbox-galery-new .item15{
  background-image: url('../img/DJOLIBA_bateau_hotel-Mali.jpg');
  width: 25%;
  height: 350px;
}

.flexbox-galery-new .item16{
  background-image: url('../img/EMMAR-Dubaï.jpg');
  width: 50%;
  height: 350px;
}

.flexbox-galery-new .item17{
  background-image: url('../img/HOTEL-Lyon.jpg');
  width: 25%;
  height: 350px;
}

.flexbox-galery-new .item25{
  background-image: url('../img/MOOR_hotel_flottant.jpg');
  width: 25%;
  height: 350px;
}

.flexbox-galery-new .item18{
  background-image: url('../img/HOTEL_COSTES-Paris.jpg');
  width: 33.3333%;
  height: 300px;
}

.flexbox-galery-new .item19{
  background-image: url('../img/HOTEL_DESERT-Libye.jpg');
  width: 33.3333%;
  height: 300px;
}

.flexbox-galery-new .item26{
  background-image: url('../img/MAISONS_ILE-Saint_Cloud.jpg');
  width: 33.3333%;
  height: 300px;
  background-position: left center;
}

.flexbox-galery-new .item20{
  background-image: url('../img/Maison_Leduc_SEINE-DESIGN_1_web.jpg');
  width: 50%;
  height: 300px;
}

.flexbox-galery-new .item27{
  background-image: url('../img/NAUTILUS_bateau_restaurant-Paris-1.jpg');
  width: 50%;
  height: 300px;
}

.flexbox-galery-new .item21{
  background-image: url('../img/LOUIS_VUITTON_showroom-Paris.jpg');
  width: 30%;
  height: 600px;
}

.flexbox-galery-new .item28{
  background-image: url('../img/NINGBO-3_bateaux_pour_3_rives-Macau.jpg');
  width: 25%;
  height: 275px;
}

.flexbox-galery-new .item29{
  background-image: url('../img/SHU-UEMURA_showroom-Paris.jpg');
  width: 25%;
  height: 275px;
}

.flexbox-galery-new .item30{
  background-image: url('../img/VILLAGE_FLOTTANTE-Bordeaux.jpg');
  width: 25%;
  height: 275px;
}

.flexbox-galery-new .item31{
  background-image: url('../img/VILLAGE_HOTEL-Havre.jpg');
  width: 25%;
  height: 275px;
}

.flexbox-galery-new .item32{
  background-image: url('../img/LES_BASSINES_baignade-a-La-Vilette-Paris.jpg');
  width: 35%;
  height: 500px;
  background-position: 50% 65%;
}

.flexbox-galery-new .item33{
  background-image: url('../img/MOOR_hotel_flottant-modulable-Paris.jpg');
  width: 35%;
  height: 500px;
}

.flexbox-galery-new .item34{
  background-image: url('../img/MOULIN_SEINE-boulangerie_flottante-Paris.jpg');
  width: 100%;
  height: 250px;
}

.flexbox-galery-new .item35{
  background-image: url('../img/NOUVELLE_TOUR_EIFFELL-rehabilitation_reception-Paris.jpg');
  width: 100%;
  height: 250px;
}

.flexbox-galery-new .item36{
  background-image: url('../img/THERMES_DE_NEYRAC-Neyrac_2.jpg');
  width: 30%;
  height: 300px;
  background-position: center bottom;
}

.flexbox-galery-new .item37{
  background-image: url('../img/HOTEL-Lyon.jpg');
  width: 40%;
  height: 300px;
  background-position: 50% 60%;
}

.flexbox-galery-new .item38{
  background-image: url('../img/CHALET_DES_ILES.jpg');
  width: 30%;
  height: 300px;
  background-position: 50% 65%;
}

.flexbox-galery-new .item39{
  background-image: url('../img/DIRIGEABLE-Bresil.jpg');
  width: 50%;
  height: 650px;
  background-position: center;
}

.flexbox-galery-new .item40{
  background-image: url('../img/MONTEBELLO-Notre_Dame-Paris.jpg');
  width: 100%;
  height: 400px;
  background-position: 50% 85%;
}

.flexbox-galery-new .item41{
  background-image: url('../img/SAUT_DU_DOUBS_bateau_croisiere-Doubs.jpg');
  width: 50%;
  height: 400px;
  background-position: 50% 85%;
}

.flexbox-galery-new .item42{
  background-image: url('../img/LE_BRETAGNE-rehabilitation_bateau_promenade-Paris.jpg');
  width: 25%;
  height: 650px;
}

.flexbox-galery-new .item43{
  background-image: url('../img/BISTROT_ALEXANDRE_III-Paris.jpg');
  width: 25%;
  height: 650px;
}

.flexbox-galery-new .item44{
  background-image: url('../img/LE_MARGUERITE_rehabilitation_peniche-Paris.jpg');
  width: 50%;
  height: 400px;
}

.flexbox-galery-new .item45{
  background-image: url('../img/TRIMARAN-rehabilitation_bateaux_trimaran-Paris.jpg');
  width: 40%;
  height: 800px;
}

.flexbox-galery-new .item46{
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_1.jpg');
  width: 33.3333%;
  height: 350px;
}


.flexbox-galery-new .item47{
  background-image: url('../img/WEB_CHARING-CROSS_Seine-Design_4.jpg');
  width: 33.3333%;
  height: 350px;
}


.flexbox-galery-new .item48{
  background-image: url('../img/VILLAGE_FLOTTANT_village_modulable.jpg');
  width: 33.3333%;
  height: 350px;
}

.flexbox-galery-new .item-col{
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

.flexbox-galery-new .item-col-30{
  display: flex;
  flex-direction: column;
  width: 30%;
}

.flexbox-galery-new .item-col-33{
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

.flexbox-galery-new .item-col-40{
  display: flex;
  flex-direction: column;
  width: 40%;
}

.flexbox-galery-new .item-col-55{
  display: flex;
  flex-wrap: wrap;
  width: 55%;
}

.flexbox-galery-new .item-col-60{
  display: flex;
  flex-wrap: wrap;
  width: 60%;
}

.flexbox-galery-new .item-col-70{
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.flexbox-galery-new .item49{
  background-image: url('../img/WEB_Pavillion Solferino_Seine Design_phase1.jpg');
  width: 50%;
  height: 400px;
}

.flexbox-galery-new .item50{
  background-image: url('../img/REHAB_SAPHIR_BOURDONNAIS.jpg');
  width: 50%;
  height: 400px;
}

.flexbox-galery-new .item51 {
  background-image: url('../img/SeineDesign_cercle-mer-flottant-theatre.jpg');
  width: 40%;
  height: 800px;
}

.flexbox-galery-new .item52 {
  background-image: url('../img/GALLERIE_ART_PONT_NEUF_seine_design_11.jpg');
  width: 100%;
  height: 400px;
  background-position: 50% 85%;
}

.flexbox-galery-new .item53 {
  background-image: url('../img/LA_DAME_DE_CANTON_Seine_Design.jpg');
  width: 50%;
  height: 400px;
}

.flexbox-galery-new .item54 {
  background-image: url('../img/WEB_2012-05-11-BESOGN-INTER-1.jpg');
  width: 50%;
  height: 400px;
  background-position: 50% 85%;
}


.flexbox-galery .item9:before{
  background: rgb(0, 0, 0);
    background: linear-gradient( 0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.40) 20%, rgba(0, 0, 0, 0.35) 35%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100% );
}

.flexbox-galery-new {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  50% {
    transform: translateY(50px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes show-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


/*----------------------SINGLE PROJECT--------------------------------*/

.fluctuart {
  width: 100%;
  overflow: hidden;
}

.fluctuart .single-Project-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.fluctuart .single-Project-container .slider-container {
  width: 77.5%;
  min-width: 1250px;
}

.fluctuart .single-Project-container section.right {
  width: 22.5%;
  padding: 80px 50px 35px;
  height: 100vh;
  min-width: 300px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background: #fff;
  margin-left: -1px;
}

.fluctuart .single-Project-container::before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #fff;
  right: 0;
  transition: all 1s ease-in-out;
  z-index: 99;
  -webkit-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
}

/*.fluctuart .transition::before{
  width: 100%;
}*/

.fluctuart #fluctuart-title a {
  pointer-events: none;
}

.fluctuart .opened#fluctuart-title a {
  pointer-events: all;
}

.fluctuart #fluctuart-title a:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 20px;
  background: url('/img/menu-close.svg') center/contain no-repeat;
  opacity: 0;
  top: 5px;
  left: -25px;
  transform: rotate(60deg);
}

.fluctuart .opened#fluctuart-title a:before {
  opacity: 1;
  transition: all 0.4s 0.2s;
  transform: rotate(0deg);
}

.fluctuart .opened2#fluctuart-title a:before {
  opacity: 1;
  transition: all 0.4s 0.1s;
  transform: rotate(45deg);
}
/*
.fluctuart .info{
  display: flex;
  flex-wrap: wrap;
}

.fluctuart .info .relateds{
  align-self: flex-end;
}

.fluctuart .info > div{
  width: 100%;
}
*/
.fluctuart .info h1 .lowercaseLetters {
  font-size: 12px;
}

.fluctuart .info h1 {
  color: #000;
  font-size: 20px;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.fluctuart h1.opened {
  margin-left: 25px;
  padding-left: 10px;
}

.fluctuart .left {
  max-width: 70%;
}

section.right .relateds {
  margin-top: 50px;
  margin-bottom: 20px;
}

.fluctuart p {
  display: none;
  margin-top: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 35px;
}

.fluctuart .relateds p {
  margin-left: 0;
}

.fluctuart .relateds h3 {
  pointer-events: none;
}

.fluctuart .relateds a {
  font-size: 10px;
  padding: 15px 10px 15px 35px;
  position: relative;
}

.fluctuart .description {
  margin-bottom: 21px;
}

.fluctuart h3 {
  font-size: 12px;
  text-transform: uppercase;
}

.fluctuart .info h3 {
  cursor: pointer;
}

.fluctuart h3::before {
  content: '+ ';
  display: inline-block;
  margin-right: 21px;
  font-weight: 600 !important;
  transition: 0.5s;
  font-family: initial;
  color: #cecaca;
  font-size: 26px;
  position: relative;
  top: 4px;
}

.fluctuart .description-open h3::before {
  transform: rotate(136deg);
}

.fluctuart .relateds a::before{
  content: '|';
    display: inline-block;
    margin-right: 6px;
    font-weight: 300!important;
    font-family: initial;
    color: #000;
    font-size: 13px;
    position: absolute;
    top: 12px;
    left: 26px;
}

.fluctuart .description-open p {
  display: block;
}

.relateds h3 {
  margin: 35px 0 15px 0;
}

.relateds h3::before {
  display: none;
}

.relateds p {
  font-size: 0.7rem;
  text-transform: uppercase;
  display: block !important;
  font-family: inherit;
}

.flutuart-transition .slide:nth-child(1) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_0.jpg');
  background-position: left center;
}

.flutuart-transition .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_1.jpg');
}

.flutuart-transition .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_2.jpg');
}

.flutuart-transition .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_3.jpg');
}

.flutuart-transition .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_4.jpg');
}

.flutuart-transition .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_5.jpg');
}

.flutuart-transition .slide:nth-child(7) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_6.jpg');
}

.flutuart-transition .slide:nth-child(8) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_7.jpg');
}

.flutuart-transition .slide:nth-child(9) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_9.jpg');
}

.flutuart-transition .slide:nth-child(10) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_10.jpg');
}

.flutuart-transition .slide:nth-child(11) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_11.jpg');
}

.flutuart-transition .slide:nth-child(12) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_12.jpg');
}

.flutuart-transition .slide:nth-child(13) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_14.jpg');
}

.flutuart-transition .slide:nth-child(14) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_15.jpg');
}

.flutuart-transition .slide:nth-child(15) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_16.jpg');
}

.flutuart-transition .slide:nth-child(16) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_17.jpg');
}

.flutuart-transition .slide:nth-child(17) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-FLUCTUART_18.jpg');
}

.info .desc-title {
  font-weight: 700;
}

.info .details-title {
  text-decoration: underline;
}

.info .details-year {
  font-weight: 600;
  padding-left: 5px;
}

.relateds a {
  -webkit-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
}

.relateds a:hover {
  margin-left: 10px;
}

.info .description:hover h3,
.fluctuart .description:hover h3::before,
.info .details:hover h3,
.fluctuart .details:hover h3::before,
.fluctuart .description-open h3,
.fluctuart .description-open h3::before {
  color: #cc2936;
}

/*-----------------ROSA--------------*/

.rosa .slide:nth-child(1) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_2_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(2) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_Home.jpg');
}

.rosa .slide:nth-child(3) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_3_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(4) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_4_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(5) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_5_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(6) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_1_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(7) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_7_outside_view_sergio_grazia.jpg');
}

.rosa .slide:nth-child(8) .slide__bg {
  background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_8_outside_view_sergio_grazia.jpg');
}

/*-----------------PERTINENCE--------------*/

.pertinence .slide:nth-child(1) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-001.jpg');
}

/*.pertinence .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-002.jpg');
}*/

.pertinence .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-003.jpg');
}

/*.pertinence .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-004.jpg');
}

.pertinence .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-005.jpg');
}*/

.pertinence .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-006.jpg');
}

/*.pertinence .slide:nth-child(7) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-007.jpg');
}*/

.pertinence .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_-_restaurant_-_paris_07_-_IMP-A-008.jpg');
}

/*-----------------HOTEL OFF SEINE--------------*/

.offSeine .slide:nth-child(1) .slide__bg {
  background-image: url('../img/SEINE_DESIGN-hotel-paris.jpg');
}

.offSeine .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-3_web.jpg');
}

.offSeine .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-4_web.jpg');
}

.offSeine .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-6_web.jpg');
}

.offSeine .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-7_web.jpg');
}

.offSeine .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-8_web.jpg');
}

.offSeine .slide:nth-child(7) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-9_web.jpg');
}

.offSeine .slide:nth-child(8) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-10_web.jpg');
}

.offSeine .slide:nth-child(9) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-11_web.jpg');
}

.offSeine .slide:nth-child(10) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-12_web.jpg');
}

.offSeine .slide:nth-child(11) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-13_web.jpg');
}

.offSeine .slide:nth-child(12) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-14_web.jpg');
}

.offSeine .slide:nth-child(13) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-15_web.jpg');
}

.offSeine .slide:nth-child(14) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-16_web.jpg');
}

.offSeine .slide:nth-child(15) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-17_web.jpg');
}

.offSeine .slide:nth-child(16) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-18_web.jpg');
}

.offSeine .slide:nth-child(17) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-19_web.jpg');
}

.offSeine .slide:nth-child(18) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-20_web.jpg');
}

.offSeine .slide:nth-child(19) .slide__bg {
  background-image: url('../img/SEINEDESIGN_hotel-flottant-paris-off-21_web.jpg');
}

/*.offSeine .slide:nth-child(20) .slide__bg {
  background-image: url('../img/photo-SG-2016-SEINE_DESIGN-hotel-paris13-IMP-C-22.jpg');
}

.offSeine .slide:nth-child(21) .slide__bg {
  background-image: url('../img/photo-SG-2016-SEINE_DESIGN-hotel-paris13-IMP-C-23.jpg');
}

.offSeine .slide:nth-child(22) .slide__bg {
  background-image: url('../img/photo-SG-2016-SEINE_DESIGN-hotel-paris13-IMP-C-24.jpg');
}

.offSeine .slide:nth-child(23) .slide__bg {
  background-image: url('../img/photo-SG-2016-SEINE_DESIGN-hotel-paris13-IMP-C-30.jpg');
}

.offSeine .slide:nth-child(24) .slide__bg {
  background-image: url('../img/photo-SG-2016-SEINE_DESIGN-hotel-paris13-IMP-C-31.jpg');
}*/

/*-----------------ADAMANT--------------*/

.adamant .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-07.jpg');
}

.adamant .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-01.jpg');
}

.adamant .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-02.jpg');
}

.adamant .slide:nth-child(4) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-03.jpg');
}

.adamant .slide:nth-child(5) .slide__bg {
  background-image: url('../img/photo_SG_2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-04_web.jpg');
}

.adamant .slide:nth-child(6) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-05.jpg');
}

.adamant .slide:nth-child(7) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-06.jpg');
}

.adamant .slide:nth-child(8) .slide__bg {
  background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-08.jpg');
}

/*-----------------CELESTIAL--------------*/

.celestial .slide:nth-child(1) .slide__bg {
  background-image: url('../img/1999_CELESTIAL_Seine_Design_1.jpg');
}

.celestial .slide:nth-child(2) .slide__bg {
  background-image: url('../img/1999_CELESTIAL_Seine_Design_2.jpg');
}

.celestial .slide:nth-child(3) .slide__bg {
  background-image: url('../img/1999_CELESTIAL_Seine_Design_3.jpg');
}

/*-----------------AL-MINSAF--------------*/

.alminsaf .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2008_AL-MINSAF_Seine_Design_1.jpg');
}

.alminsaf .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2008_AL-MINSAF_Seine_Design_2.jpg');
}

.alminsaf .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2008_AL-MINSAF_Seine_Design_3.jpg');
  background-position: center bottom;
}

/*-----------------CRISTAL II--------------*/

.cristal .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2010_CRISTAL_II_Seine_Design_1.jpg');
}

.cristal .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2010_CRISTAL_II_Seine_Design_2.jpg');
}

.cristal .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2010_CRISTAL_II_Seine_Design_3.jpg');
}

/*-----------------L'AVANT--------------*/

.l_avant .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2015_EPINAY_SUR_SEINE_Seine Design_1_web.jpg');
  background-position: 100% 70%;
}

.l_avant .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2015_EPINAY_SUR_SEINE_Seine Design_2_web.jpg');
}

.l_avant .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2015_EPINAY_SUR_SEINE_Seine Design_3_web.jpg');
}

.l_avant .slide:nth-child(4) .slide__bg {
  background-image: url('../img/2015_EPINAY_SUR_SEINE_Seine Design_4_web.jpg');
}

/*-----------------L'ARCHE PORT DE JAVEL BAS--------------*/

.l_arche .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_2.jpg');
}

.l_arche .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_1.jpg');
}

.l_arche .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_3.jpg');
}

.l_arche .slide:nth-child(4) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_4.jpg');
}

.l_arche .slide:nth-child(5) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_5.jpg');
}

.l_arche .slide:nth-child(6) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_6.jpg');
}

.l_arche .slide:nth-child(7) .slide__bg {
  background-image: url('../img/2021_L-ARCHE_Seine-Design_7.jpg');
}

/*-----------------ECOLE DUCASSE--------------*/

.ecole_ducasse .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_1.jpg');
}

.ecole_ducasse .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_2.jpg');
}

.ecole_ducasse .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_3.jpg');
}

.ecole_ducasse .slide:nth-child(4) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_4.jpg');
}

.ecole_ducasse .slide:nth-child(5) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_5.jpg');
}

.ecole_ducasse .slide:nth-child(6) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_6.jpg');
}

.ecole_ducasse .slide:nth-child(7) .slide__bg {
  background-image: url('../img/2017_ECOLE-DUCASSE_Seine_Design_7.jpg');
}

/*-----------------DUCASSE--------------*/

.ducasse .slide:nth-child(1) .slide__bg {
  background-image: url('../img/2018_DUCASSE_EN_SEINE_Seine_Design_1.jpg');
  background-position: center bottom;
}

.ducasse .slide:nth-child(2) .slide__bg {
  background-image: url('../img/2018_DUCASSE_EN_SEINE_Seine_Design_2.jpg');
}

.ducasse .slide:nth-child(3) .slide__bg {
  background-image: url('../img/2018_DUCASSE_EN_SEINE_Seine_Design_3.jpg');
}

/*-----------------DESERT--------------*/

.desert .slide:nth-child(1) .slide__bg {
  background-image: url('../img/HOTEL_DESERT-Libye.jpg');
}

.desert .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-desert-libye-1.jpg');
}

.desert .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-desert-libye-3.jpg');
}

.desert .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-desert-libye-4.jpg');
}

.desert .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-desert-libye-5.jpg');
}

/*-----------------NANDA-DEVI--------------*/

.devi .slide:nth-child(1) .slide__bg {
  background-image: url('../img/NANDA_DEVI_Galerie_dArt-Paris.jpg');
}

.devi .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINEDESIGN_nanda-devi-art-gallery-1.jpg');
}

.devi .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINEDESIGN_nanda-devi-art-gallery-2.jpg');
  background-position: bottom center;
}

.devi .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINEDESIGN_nanda-devi-art-gallery-3.jpg');
  background-position: top left;
}

.devi .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINEDESIGN_nanda-devi-art-gallery-5.jpg');
}

/*----------------- MUSEE-DALI--------------*/

.musee_dali .slide:nth-child(1) .slide__bg {
  background-image: url('../img/WEB_SeineDesign_musee-flottant-salvador-dali-2.jpg');
  background-position: top right;
}

.musee_dali .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB_SeineDesign_musee-flottant-salvador-dali-1.jpg');
}

.musee_dali .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB_SeineDesign_musee-flottant-salvador-dali-3.jpg');
  background-position: bottom center;
}

/*-----------------HOTEL COSTES--------------*/

.costes .slide:nth-child(1) .slide__bg {
  background-image: url('../img/HOTEL_COSTES-Paris.jpg');
}

.costes .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-costes-flottant-1.jpg');
}

.costes .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_hotel-costes-flottant-2.jpg');
}

/*-----------------MAISON ILES--------------*/

.maison_iles .slide:nth-child(1) .slide__bg {
  background-image: url('../img/MAISONS_ILE-Saint_Cloud.jpg');
}

.maison_iles .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_maison-iles-1.jpg');
  background-position: top center;
}

.maison_iles .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_maison-iles-2.jpg');
}

.maison_iles .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SeineDesign_maison-iles-4.jpg');
}

.maison_iles .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SeineDesign_maison-iles-5.jpg');
}

.maison_iles .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SeineDesign_maison-iles-6.jpg');
}

/*-----------------NINGBO 3--------------*/

.ningbo .slide:nth-child(1) .slide__bg {
  background-image: url('../img/NINGBO-3_bateaux_pour_3_rives-Macau.jpg');
}

.ningbo .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINEDESIGN_ningbo-macao-floating-2.jpg');
}

.ningbo .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINEDESIGN_ningbo-macao-floating-3.jpg');
}

.ningbo .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINEDESIGN_ningbo-macao-floating-4.jpg');
}

.ningbo .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINEDESIGN_ningbo-macao-floating-5.jpg');
}

.ningbo .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SEINEDESIGN_ningbo-macao-floating-6.jpg');
}

/*-----------------bassines--------------*/

.bassines .slide:nth-child(1) .slide__bg {
  background-image: url('../img/LES_BASSINES_baignade-a-La-Vilette-Paris.jpg');
  background-position: 50% 65%;
}

.bassines .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_les-bassines-la-vilette-2.jpg');
  background-position: center;
}

.bassines .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_les-bassines-la-vilette-4.jpg');
}

/*-----------------NOUVELLE--------------*/

.nouvelle_eiffel .slide:nth-child(1) .slide__bg {
  background-image: url('../img/NOUVELLE_TOUR_EIFFELL-rehabilitation_reception-Paris.jpg');
}

.nouvelle_eiffel .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_nouvelle-tour-eiffel-3.jpg');
}

.nouvelle_eiffel .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_nouvelle-tour-eiffel-4.jpg');
}

.nouvelle_eiffel .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SeineDesign_nouvelle-tour-eiffel-5.jpg');
}

/*-----------------NAUTILUS--------------*/

.nautilus .slide:nth-child(1) .slide__bg {
  background-image: url('../img/NAUTILUS_bateau_restaurant-Paris-1.jpg');
}

.nautilus .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_nautilus-2.jpg');
}

.nautilus .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_nautilus-3.jpg');
}

/*-----------------MOULIN SEINE--------------*/

.moulin_seine .slide:nth-child(1) .slide__bg {
  background-image: url('../img/MOULIN_SEINE-boulangerie_flottante-Paris.jpg');
  background-position: 50% 30%;
}

.moulin_seine .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_moulin-seine-1.jpg');
  background-position: top center;
}

.moulin_seine .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_moulin-seine-3.jpg');
}

.moulin_seine .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SeineDesign_moulin-seine-4.jpg');
}

.moulin_seine .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SeineDesign_moulin-seine-5.jpg');
}


/*-----------------LOUIS VUITTON--------------*/

.louis_vuitton .slide:nth-child(1) .slide__bg {
  background-image: url('../img/LOUIS_VUITTON_showroom-Paris.jpg');
  background-position: 50% 30%;
}

.louis_vuitton .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-0.jpg');
  background-position: top center;
}

.louis_vuitton .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-1.jpg');
}

.louis_vuitton .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-3.jpg');
}

.louis_vuitton .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-4.jpg');
}

.louis_vuitton .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-5.jpg');
}

.louis_vuitton .slide:nth-child(7) .slide__bg {
  background-image: url('../img/SeineDesign_louis-vuitton-6.jpg');
}

/*-----------------LE BRETAGNE--------------*/

.l_bretagne .slide:nth-child(1) .slide__bg {
  background-image: url('../img/LE_BRETAGNE-rehabilitation_bateau_promenade-Paris.jpg');
  background-position: 50% 75%;
}

.l_bretagne .slide:nth-child(2) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_2.jpg');
}

.l_bretagne .slide:nth-child(3) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_3.jpg');
}

.l_bretagne .slide:nth-child(4) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_4.jpg');
}

.l_bretagne .slide:nth-child(5) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_5.jpg');
}

.l_bretagne .slide:nth-child(6) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_6.jpg');
}

.l_bretagne .slide:nth-child(7) .slide__bg {
  background-image: url('../img/Seine_Design_LE-BRETAGNE_7.jpg');
}

/*-----------------SAUT DU DOUBS--------------*/

.saut_doubs .slide:nth-child(1) .slide__bg {
  background-image: url('../img/SAUT_DU_DOUBS_bateau_croisiere-Doubs.jpg');
}

.saut_doubs .slide:nth-child(2) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_Saut_du_Doubs_2.jpg');
}

.saut_doubs .slide:nth-child(3) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_Saut_du_Doubs_3.jpg');
}

.saut_doubs .slide:nth-child(4) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_Saut_du_Doubs_4.jpg');
  background-position: 50% 70%;
}

.saut_doubs .slide:nth-child(5) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_Saut_du_Doubs_5.jpg');
}

.saut_doubs .slide:nth-child(6) .slide__bg {
  background-image: url('../img/SEINE_DESIGN_Saut_du_Doubs_6.jpg');
  background-position: center bottom;
}

/*-----------------CHARING CROSS--------------*/

.charing_cross .slide:nth-child(1) .slide__bg {
  background-image: url('../img/WEB_CHARING-CROSS_Seine-Design_4.jpg');
}

.charing_cross .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB_CHARING-CROSS_Seine-Design_5.jpg');
}

.charing_cross .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB_CHARING_CROSS_Seine_Design_3.jpg');
}

.charing_cross .slide:nth-child(4) .slide__bg {
  background-image: url('../img/WEB_CHARING_CROSS_Seine_Design_2.jpg');
  background-position: 50% 70%;
}

.charing_cross .slide:nth-child(5) .slide__bg {
  background-image: url('../img/WEB_CHARING_CROSS_Seine_Design_1.jpg');
}

.charing_cross .slide:nth-child(6) .slide__bg {
  background-image: url('../img/WEB_CHARING-CROSS_Seine-Design_5.jpg');
  background-position: center bottom;
}

/*-----------------PAVILLION SOLFERINO--------------*/

.pavillion_solferino .slide:nth-child(1) .slide__bg {
  background-image: url('../img/WEB_Pavillion Solferino_Seine Design_phase1.jpg');
}

.pavillion_solferino .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB_Pavillion Solferino_Seine Design_phase2.jpg');
}

.pavillion_solferino .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB_Pavillion Solferino_Seine Design_phase3.jpg');
}

.pavillion_solferino .slide:nth-child(4) .slide__bg {
  background-image: url('../img/WEB_Pavillion-Solferino_Seine-Design_qassemblage.jpg');
}

/*-----------------ROSA BONHEUR - ASNIERES--------------*/

.rosa_bonheur_asnieres .slide:nth-child(1) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_1.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_2.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_3.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(4) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_4.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(5) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_5.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(6) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_6.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(7) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_7.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(8) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_8.jpg');
}

.rosa_bonheur_asnieres .slide:nth-child(9) .slide__bg {
  background-image: url('../img/WEB__rosa_bonheur_sur_seine_seine_design_9.jpg');
}


/*-----------------LE MARGUERITE--------------*/

.le_marguerite .slide:nth-child(1) .slide__bg {
  background-image: url('../img/LE_MARGUERITE_rehabilitation_peniche-Paris.jpg');
}

.le_marguerite .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB_Seine_Design_Le_Marguerite_0.jpg');
}

.le_marguerite .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB_Seine_Design_Le_Marguerite_1.jpg');
}

.le_marguerite .slide:nth-child(4) .slide__bg {
  background-image: url('../img/WEB_Seine_Design_Le_Marguerite_2.jpg');
}

.le_marguerite .slide:nth-child(5) .slide__bg {
  background-image: url('../img/WEB_Seine_Design_Le_Marguerite_3.jpg');
}

.le_marguerite .slide:nth-child(6) .slide__bg {
  background-image: url('../img/WEB_Seine_Design_Le_Marguerite_4.jpg');
}

/*-----------------TRIMARAN--------------*/

.trimaran .slide:nth-child(1) .slide__bg {
  background-image: url('../img/WEB_SEINE_DESIGN_Trimarans0.jpg');
}

.trimaran .slide:nth-child(2) .slide__bg {
  background-image: url('../img/WEB_SEINE_DESIGN_Trimarans1.jpg');
}

.trimaran .slide:nth-child(3) .slide__bg {
  background-image: url('../img/WEB_SEINE_DESIGN_Trimarans2.jpg');
}

.trimaran .slide:nth-child(4) .slide__bg {
  background-image: url('../img/WEB_SEINE_DESIGN_Trimarans3.jpg');
}


.single-Project-container > .mobile_btns,
.single-Project-container .mobile_page_title {
  display: none;
}


@media only screen and (max-width: 1600px){
  .flexbox-galery-new .item1{
    height: 650px;
  }

  .flexbox-galery-new .item2,
  .flexbox-galery-new .item3{
    height: 450px;
  }

  .flutuart-transition .slide:nth-child(1) .slide__bg,
  .flutuart-transition .slide:nth-child(2) .slide__bg{
    background-position: center;
  }
}

@media only screen and (max-width: 1440px){
  .flexbox-galery-new .item2{
    background-position: left bottom;
  }

  .flexbox-galery-new .item4{
    height: 700px;
  }

  .flexbox-galery-new .item5,
  .flexbox-galery-new .item6{
    height: 350px;
  }
}

@media only screen and (max-width: 1366px) {
  .flexbox-galery-new > .item1 {
    height: 550px;
  }

  .flexbox-galery-new .item2,
  .flexbox-galery-new .item3 {
      height: 400px;
  }

  .flexbox-galery-new .item4{
    height: 550px;
  }

  .flexbox-galery-new .item5,
  .flexbox-galery-new .item6{
    height: 275px;
  }

  .flexbox-galery-new .item7,
  .flexbox-galery-new .item8,
  .flexbox-galery-new .item10,
  .flexbox-galery-new .item11{
    height: 500px;
  }

  .flexbox-galery-new .item9{
    height: 600px;
  }

  .flexbox-galery-new .item15,
  .flexbox-galery-new .item16,
  .flexbox-galery-new .item17,
  .flexbox-galery-new .item25{
    height: 250px;
  }
}

@media only screen and (max-width: 1280px){
  .flexbox-galery-new > .item1 {
    height: 450px;
  }
  .flexbox-galery-new .item4{
    background-size: 100%;
  }
  .flexbox-galery-new .item12{
    height: 450px;
    background-position: center right;
  }

  .flexbox-galery-new .item13,
  .flexbox-galery-new .item22,
  .flexbox-galery-new .item23{
    height: 225px;
  }

  .flexbox-galery-new .item18,
  .flexbox-galery-new .item19,
  .flexbox-galery-new .item26,
  .flexbox-galery-new .item20,
  .flexbox-galery-new .item27{
    height: 250px;
  }

  .flexbox-galery-new .item21{
    height: 500px;
  }

  .flexbox-galery-new .item26{
    background-position: center left;
  }
}

@media only screen and (max-width: 1100px){
  .flexbox-galery-new .item-col-70{
    width: 75%;
  }

  .flexbox-galery-new .item21{
    width: 25%;
  }

  .flexbox-galery-new .item4 {
    background-size: cover;
  }
}

@media only screen and (max-width: 1024px) {
  .flexbox-galery-new .item2,
  .flexbox-galery-new .item3 {
    height: 325px;
  }
  .flexbox-galery-new .item2{
    background-position: center;
  }
  .flexbox-galery-new .item4 {
    height: 500px;
  }
  .flexbox-galery-new .item5, .flexbox-galery-new .item6 {
    height: 250px;
  }
  .flexbox-galery-new .item7,
  .flexbox-galery-new .item8,
  .flexbox-galery-new .item10,
  .flexbox-galery-new .item11 {
    height: 400px;
  }
  .flexbox-galery-new .item9 {
    height: 500px;
  }

  .flexbox-galery-new .item15{
    width: 35%;
  }

  .flexbox-galery-new .item15,
  .flexbox-galery-new .item16,
  .flexbox-galery-new .item17,
  .flexbox-galery-new .item25,
  .flexbox-galery-new .item18,
  .flexbox-galery-new .item19,
  .flexbox-galery-new .item26,
  .flexbox-galery-new .item20,
  .flexbox-galery-new .item27 {
    height: 200px;
  }

  .flexbox-galery-new .item21 {
    height: 400px;
  }

  .flexbox-galery-new .item28,
  .flexbox-galery-new .item29,
  .flexbox-galery-new .item30,
  .flexbox-galery-new .item31{
    height: 200px;
  }

  .flexbox-galery-new .item32,
  .flexbox-galery-new .item33{
    height: 400px;
  }

  .flexbox-galery-new .item34,
  .flexbox-galery-new .item35{
    height: 200px;
  }

  .flexbox-galery-new .item36,
  .flexbox-galery-new .item38{
    width: 25%;
    height: 200px;
  }

  .flexbox-galery-new .item37,
  .flexbox-galery-new .item46,
  .flexbox-galery-new .item47,
  .flexbox-galery-new .item48,
  .flexbox-galery-new .item49{
    width: 50%;
    height: 200px;
  }

  .flexbox-galery-new .item30{
    background-position: center top;
  }

  .flexbox-galery-new .item34,
  .flexbox-galery-new .item35{
    height: 200px;
  }

  .flexbox-galery-new .item36,
  .flexbox-galery-new .item37,
  .flexbox-galery-new .item38{
    width: 33.3333%;
  }

  .flexbox-galery-new .item25{
    width: 20%;
  }

  .flexbox-galery-new .item15{
    width: 30%;
  }

  .flexbox-galery-new .item16{
    width: 50%;
  }

  .flexbox-galery-new .item39{
    height: 500px;
    width: 70%;
  }

  .flexbox-galery-new .item43{
    height: 500px;
    width: 30%;
  }

  .flexbox-galery-new .item42{
    height: 500px;
    width: 50%;
  }

  .flexbox-galery-new .item45{
    height: 500px;
    width: 50%;
  }

  .flexbox-galery-new .item-col-60 {
    flex-wrap: nowrap;
    width: 100%;
  }

  .flexbox-galery-new .item40{
    width: 33.3333%;
  }

  .flexbox-galery-new .item44{
    width: 33.3333%;
  }

  .flexbox-galery-new .item41{
    width: 33.3333%;
  }

  .flexbox-galery-new .item50{
    width: 66.66%;
    height: 400px;
  }

  .flexbox-galery-new .item51{
    width: 33.33%;
    height: 400px;
  }

  .flexbox-galery-new .item52,
  .flexbox-galery-new .item53,
  .flexbox-galery-new .item54{
    width: 33.33%;
    height: 200px;
  }
  
}

@media only screen and (max-width: 800px) {
  .flexbox-galery-new > .item1,
  .flexbox-galery-new .item3 {
    height: 350px;
    width: 100%;
  }

  .flexbox-galery-new .item2{
    width: 100%;
    height: 400px;
    background-position: bottom center;
  }

  .flexbox-galery-new .item4{
    width: 100%;
    height: 400px;
  }

  .flexbox-galery-new .item-col{
    width: 50%;
  }

  .flexbox-galery-new .item5, .flexbox-galery-new .item6{
    height: 300px;
  }

  .flexbox-galery-new .item7{
    height: 600px;
  }

  .flexbox-galery-new .item8,
  .flexbox-galery-new .item9{
    width: 100%;
    height: 350px;
    background-position: center bottom;
  }

  .flexbox-galery-new .item10,
  .flexbox-galery-new .item11{
    height: 300px;
  }

  .flexbox-galery-new .item12 {
    height: 350px;
    width: 100%;
    background-position: center;
  }

  .flexbox-galery-new .item-col-55{
    width: 65%;
  }

  .flexbox-galery-new .item22,
  .flexbox-galery-new .item23{
    height: 250px;
  }

  .flexbox-galery-new .item15,
  .flexbox-galery-new .item16{
    height: 250px;
  }

  .flexbox-galery-new .item15{
    width: 40%;
  }

  .flexbox-galery-new .item16{
    width: 60%;
  }

  .flexbox-galery-new .item25{
    width: 100%;
    height: 400px;
    background-position: 50% 60%;
  }

  .flexbox-galery-new .item-col-70{
    width: 100%;
  }

  .flexbox-galery-new .item21 {
    height: 200px;
    width: 20%;
  }

  .flexbox-galery-new .item28,
  .flexbox-galery-new .item29{
    width: 40%;
  }

  .flexbox-galery-new .item30,
  .flexbox-galery-new .item31{
    width: 50%;
  }

  .flexbox-galery-new .item-col-55 {
    width: 100%;
  }

  .flexbox-galery-new .item13{
    height: 350px;
  }

  .flexbox-galery-new .item18,
  .flexbox-galery-new .item19 {
    width: 50%;
    height: 250px;
  }

  .flexbox-galery-new .item26{
    height: 250px;
    width: 60%;
  }

  .flexbox-galery-new .item20{
    height: 250px;
    width: 40%;
  }

  .flexbox-galery-new .item27,
  .flexbox-galery-new .item25,
  .flexbox-galery-new .item41,
  .flexbox-galery-new .item45,
  .flexbox-galery-new .item42{
    width: 100%;
    height: 375px;
  }

  .flexbox-galery-new .item28,
  .flexbox-galery-new .item21,
  .flexbox-galery-new .item29{
    height: 250px;
  }

  .flexbox-galery-new .item30,
  .flexbox-galery-new .item31{
    height: 300px;
  }

  .flexbox-galery-new .item39,
  .flexbox-galery-new .item43 {
    height: 350px;
  }

  .flexbox-galery-new .item42 {
    width: 70%;
    background-position: 50% 70%;
  }

  .flexbox-galery-new .item45 {
    width: 30%;
  }

  .flexbox-galery-new .item40,
  .flexbox-galery-new .item41,
  .flexbox-galery-new .item44{
    height: 250px;
  }
}

@media only screen and (max-width: 720px) {
  /*.flexbox-galery-new .item[data-aos='fade-left'] {
    -webkit-transform: translate3d(0, 100px, 0) !important;
    transform: translate3d(0, 100px, 0) !important;
  }*/

  .flexbox-galery-new > .item.item-2-3 {
    width: 100%;
  }

  .flexbox-galery-new .item-1-3 {
    width: 100%;
    flex-direction: row;
  }

  .flexbox-galery-new .item-1-3 .item {
    height: 235px;
    width: 50%;
  }

  .flexbox-galery-new .item-adamant.item-1-2-1,
  .flexbox-galery-new .item-rosa.item-1-2-2 {
    height: 400px;
  }

  .flexbox-galery-new .item-offseine.item-1-3,
  .flexbox-galery-new .item-arche.item-2-3 {
    height: 465px;
    width: 100%;
  }
/*
  .flexbox-galery-new .item1 {
    order: -1;
  }

  .flexbox-galery-new .item6 {
    order: 0;
  }

  .flexbox-galery-new .items-3 {
    order: 1;
  }

  .flexbox-galery-new .items-2 {
    order: 5;
  }

  .project-galery > .flexbox-galery-new .item7 {
    order: 2;
    width: 100%;
  }

  .project-galery > .flexbox-galery-new > .item8 {
    order: 3;
    width: 100%;
  }

  .flexbox-galery-new .item10 {
    order: 7;
  }
*/
  .flexbox-galery-new .item-col {
    width: 50%;
    flex-direction: column;
  }

  .flexbox-galery-new .item5, .flexbox-galery-new .item6 {
    height: 250px;
  }

  .flexbox-galery-new .item7{
    height: 500px;
  }

  .flexbox-galery-new .item39,
  .flexbox-galery-new .item42 {
    width: 60%;
  }

  .flexbox-galery-new .item43,
  .flexbox-galery-new .item45 {
    width: 40%;
  }

  .flexbox-galery-new .item-col-60 {
    flex-wrap: wrap;
  }

  .flexbox-galery-new .item40,
  .flexbox-galery-new .item44 {
    width: 50%;
  }

  .flexbox-galery-new .item41{
    width: 100%;
    height: 375px;
  }
}

@media only screen and (max-width: 600px) {
  .flexbox-galery-new > .item.item-2-3 {
    height: 375px;
  }

  .flexbox-galery-new .item2 {
    background-position: 10% 70%;
  }

  .flexbox-galery-new .item7 {
    height: 400px;
  }

  .flexbox-galery-new .item5, .flexbox-galery-new .item6 {
    height: 200px;
  }

  .flexbox-galery-new .item26,
  .flexbox-galery-new .item20 {
    width: 50%;
  }

  .flexbox-galery-new .item21,
  .flexbox-galery-new .item28{
    height: 300px;
  }

  .flexbox-galery-new .item21,
  .flexbox-galery-new .item32{
    width: 35%;
    height: 300px;
  }

  .flexbox-galery-new .item28,
  .flexbox-galery-new .item31{
    width: 65%;
  }

  .flexbox-galery-new .item29,
  .flexbox-galery-new .item30{
    width: 50%;
  }

  .flexbox-galery-new .item30{
    height: 250px;
  }

  .flexbox-galery-new .item33{
    width: 50%;
    height: 400px;
  }

  .flexbox-galery-new .item-col-30 {
    width: 50%;
  }

  .flexbox-galery-new .item39, .flexbox-galery-new .item43 {
    height: 300px;
  }

  .flexbox-galery-new .item36{
    width: 40%;
  }

  .flexbox-galery-new .item37{
    width: 60%;
  }

  .flexbox-galery-new .item38{
    height: 300px;
    width: 100%;
  }

  .flexbox-galery-new .item42 {
    height: 400px;
    width: 65%;
  }

  .flexbox-galery-new .item45 {
    width: 35%;
    height: 400px;
  }

  .flexbox-galery-new .item41 {
    height: 300px;
  }

  .flexbox-galery-new .item-col,
  .flexbox-galery-new .item-col-30{
    width: 100%;
  }

  .project-galery > .flexbox-galery-new .item{
    width: 100%;
    height: 450px;
    -webkit-transform: translate3d(0,0,0)!important;
    transform: translate3d(,0,0)!important;
  }
}

@media only screen and (max-width: 480px) {
  .flexbox-galery-new .item-1-3 .item {
    height: 215px;
  }

  .flexbox-galery-new > .item.item-2-3,
  .flexbox-galery-new .item-adamant.item-1-2-1,
  .flexbox-galery-new .item-rosa.item-1-2-2,
  .flexbox-galery-new .item-offseine.item-1-3,
  .flexbox-galery-new .item-arche.item-2-3 {
    height: 375px;
  }

  .flexbox-galery-new .item2 {
    background-position: center;
}

  .flexbox-galery-new .item{
    height: 350px;
  }
}

@media only screen and (max-width: 414px) {
  .flexbox-galery-new .item-1-3 .item {
    height: 200px;
  }

  .flexbox-galery-new > .item.item-2-3,
  .flexbox-galery-new .item-adamant.item-1-2-1,
  .flexbox-galery-new .item-rosa.item-1-2-2,
  .flexbox-galery-new .item-offseine.item-1-3,
  .flexbox-galery-new .item-arche.item-2-3 {
    height: 335px;
  }
}

/*-----MEDIA QUERIES-----*/

@media only screen and (max-width: 1600px) {
  .fluctuart .single-Project-container .slider-container {
    min-width: 1200px;
  }

  .fluctuart .single-Project-container section.right {
    padding: 80px 35px 35px;
  }

  .fluctuart .info h1 {
    font-size: 18px;
  }

  .fluctuart #fluctuart-title a:before {
    top: 5px;
  }

  .fluctuart #fluctuart-title a:before {
    width: 14px;
    height: 18px;
  }
}

@media only screen and (max-width: 1500px) {
  .fluctuart .single-Project-container section.right {
    min-width: 250px;
  }

  .fluctuart .single-Project-container .slider-container {
    min-width: 1000px;
  }

  .fluctuart .single-Project-container .slider-container {
    width: 77.5%;
  }

  .fluctuart .single-Project-container section.right {
    width: 22.5%;
  }
}

@media only screen and (max-width: 1366px) {
  .fluctuart .single-Project-container section.right {
    padding: 50px 20px 25px 35px;
  }

  section.right .relateds {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1280px) {
  .fluctuart .single-Project-container .slider-container {
    min-width: 0;
  }

  .fluctuart .single-Project-container section.right {
    padding: 50px 20px 25px 50px;
  }

  .fluctuart .single-Project-container .slider-container {
    width: 100%;
  }

  .fluctuart .single-Project-container .slider-container {
    width: 100%;
  }

  .fluctuart .single-Project-container section.right {
    position: absolute;
    width: 375px;
    right: -375px;
    -webkit-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -moz-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -o-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    overflow-x: hidden;
    z-index: 999;
    opacity: 1 !important;
  }

  .fluctuart .single-Project-container{
    position: relative;
  }


  .single-Project-container .mobile_btns {
    display: flex;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  .single-Project-container .mobile_btns .btn-arrow {
    width: 50px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_btns .btn-arrow svg {
    fill: #000;
    width: 50px;
    padding: 7.5px 14px;
    background: #fff;
  }

  .single-Project-container .mobile_btns .next_btn {
    color: #fff;
  }

  .single-Project-container .mobile_btns .prev_btn {
    color: #fff;
  }

  .single-Project-container .mobile_btns .more_details_btn {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: #fff;
    z-index: 2;
    border-radius: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    -moz-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    -o-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
  }

  .single-Project-container .mobile_btns .more_details_btn svg polygon{
    margin: 0;
    -webkit-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      display: flex;
      align-items: center;
      stroke: #000;
      fill: #000;
      stroke-width: 20;
  }

  .mobile_btns .more_details_btn::-moz-selection svg {
     background: transparent;
    }
  .mobile_btns .more_details_btn::selection svg {
    background: transparent;
  }

  .mobile_btns .more_details_btn:hover::-moz-selection svg {
    background: transparent;
   }
  .mobile_btns .more_details_btn:hover::selection svg {
   background: transparent;
  }

  .single-Project-container .mobile_btns .more_details_btn:hover {
    background: #cc2936;
    color: #fff;
  }

  .single-Project-container .mobile_btns .more_details_btn:hover polygon{
    fill: #fff;
    stroke: #fff;
  }

  .main-wrapper.details-open .more_details_btn {
    background: #cc2936;
    color: #fff;
    transform: translateX(-375px) rotate(-135deg);
    -webkit-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -moz-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -o-transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      transform 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  }

  .main-wrapper.details-open .more_details_btn svg polygon{
    color: #fff;
    -webkit-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      stroke: #fff;
      fill: #fff;
  }

  .main-wrapper.details-open .single-Project-container section.right {
    position: absolute;
    width: 375px;
    right: 0;
    -webkit-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    -moz-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    -o-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 150ms;
    transform: translate(0, 0) !important;
  }

  .fluctuart .mobile_page_title {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    z-index: 999;
    transform: translate(-50%, -50%);
  }

  .fluctuart .mobile_page_title #mobile-title {
    font-size: 36px;
    transform: translateX(50px);
    opacity: 0;
    color: #cc2936;
  }

  .mobile_page_title .mobile-title-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_page_title .mobile-title-details > #mobile-subtitle{
    margin-right: 25px;
    transform: translateX(50px);
    opacity: 0;
  }

  .mobile_page_title .mobile-title-details > #mobile-year{
    opacity: 0;
    transform: translateX(50px);
  }

  .single-Project-container #mobile-subtitle span{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }
}

@media only screen and (max-width: 1110px){
  .flutuart-transition .slide:nth-child(1) .slide__bg{
    background-position: center;
  }
}

@media only screen and (max-device-width: 800px){

  .fluctuart .single-Project-container{
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  .fluctuart .mobile_page_title #mobile-title{
    font-size: 30px;
  }
  
  .fluctuart .single-Project-container section.right {
    padding: 50px 20px 25px 35px;
  }
}

@media only screen and (max-width: 600px) {
  .fluctuart .single-Project-container section.right {
    padding: 40px 20px 25px 50px;
  }

  .single-Project-container .mobile_btns .more_details_btn:hover{
    background: #fff;
    color: #000;
  }

  .single-Project-container .mobile_btns .more_details_btn:hover polygon{
    fill: #000;
    stroke: #000;
  }

  .details-open .single-Project-container .mobile_btns .more_details_btn polygon{
    fill: #fff;
    stroke: #fff;
  }

  .main-wrapper.details-open .more_details_btn svg #plus polygon{
    fill: #fff;
    stroke: #fff;
  }

  .main-wrapper.details-open .mobile_btns > .more_details_btn{
    background: #cc2936;
    color: #fff;
  }

  .fluctuart .single-Project-container section.right{
    overflow-y: scroll;
  }

  .fluctuart .mobile_page_title #mobile-title{
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .fluctuart .single-Project-container section.right {
    width: 325px;
    right: -325px;
    padding: 40px 35px 50px 35px;
  }

  .main-wrapper.details-open .single-Project-container section.right {
    width: 325px;
  }

  .single-Project-container .mobile_btns {
    bottom: 15px;
    right: 15px;
  }

  .main-wrapper.details-open .more_details_btn {
    transform: translateX(-325px) rotate(-135deg);
  }

  .fluctuart h3::before {
    margin-right: 10px;
  }

  .fluctuart p {
    margin-left: 25px;
  }

  .fluctuart .relateds a {
    padding: 15px 10px 15px 25px;
  }

  .fluctuart .info h1 {
    margin-bottom: 35px;
  }

  .fluctuart .mobile_page_title #mobile-title {
    font-size: 20px;
    min-width: 350px;
  }

  .mobile_page_title .mobile-title-details > h4{
    min-width: 205px;
    font-size: 14px;
  }

  .mobile_page_title .mobile-title-details > h5{
    font-size: 12px;
  }

  .fluctuart .relateds a::before{
    left: 15px;
  }
}

@media only screen and (max-width: 414px) {
  .main-wrapper.details-open .single-Project-container section.right {
    width: 300px;
  }

  .main-wrapper.details-open .more_details_btn {
    transform: translateX(-300px) rotate(-135deg);
  }
}

@media only screen and (max-width: 375px) {
  .main-wrapper.details-open .single-Project-container section.right {
    width: 275px;
  }

  .main-wrapper.details-open .more_details_btn {
    transform: translateX(-275px) rotate(-135deg);
  }

  .fluctuart .mobile_page_title #mobile-title {
    font-size: 18px;
    min-width: 290px;
  }

  section.right .relateds {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 360px) {
  .fluctuart .single-Project-container section.right {
    padding: 40px 15px 25px 30px;
  }

  .fluctuart p {
    font-size: 11px;
  }
}

@media only screen and (max-width: 325px) {
  .main-wrapper.details-open .single-Project-container section.right {
    width: 250px;
  }

  .main-wrapper.details-open .more_details_btn {
    transform: translateX(-245px) rotate(-135deg);
  }

  .fluctuart .mobile_page_title #mobile-title {
    font-size: 16px;
    min-width: 0;
  }
}

@media only screen and (max-height: 550px){
  .header-container .menu .menu-item{
    font-size: 14px;
  }

  h1 {
    font-size: 32px;
    line-height: normal;
  }

  .top-bar .atelier-text{
    height: 0;
  }

  .atelier-open .top-bar .atelier-text{
    height: 100%;
  }

  .fluctuart .mobile_page_title #mobile-title{
    font-size: 18px;
  }

  .menu #contact-menu:before, .menu #atelier-menu::before{
    top: 1.5px;
  }
}
/*-----------------------------ATELIER--------------------------*/

.atelier-container h1 {
  margin: 0;
  height: 75px;
  overflow: hidden;
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 30px;
  margin-bottom: 0;
  color: #000;
}

.atelier-container p,
.politics-content p {
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.atelier-container p {
  max-width: 1600px;
  margin-top: 0;
}

.politics-content p {
  font-size: 13px;
}

@media only screen and (max-width: 1600px) {
  .atelier-container h1 {
    font-size: 18px;
    height: 65px;
  }
}

@media only screen and (max-width: 1440px) {
  .atelier-container p,
  .politics-content p {
    font-size: 14px !important;
  }

  .atelier-open .top-bar{
    height: 355px;
  }
}
@media only screen and (max-width: 1366px){
  .atelier-container p, .politics-content p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1150px) {
  .top-bar .atelier-container {
    padding: 10px 50px 10px 30px;
  }

  .atelier-open .atelier-container {
    padding: 10px 50px 10px 55px;
  }
}
@media only screen and (max-width: 991px) {
  .atelier-container h1 {
    margin-bottom: 15px;
    height: auto;
    overflow: visible;
  }

  .atelier-open .top-bar {
    height: 450px;
    z-index: 999;
  }
}

@media only screen and (max-width: 800px) {
  .top-bar .atelier-container {
    padding: 10px 50px 10px 20px;
  }

  .atelier-open .atelier-container {
    padding: 10px 35px 10px 35px;
  }
}

@media only screen and (max-width: 768px) {
  .atelier-container p,
  .politics-content p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 601px) {
  .atelier-open .top-bar {
    height: 100%;
  }
  .top-bar .atelier-container {
    padding: 10px 35px 10px 0px;
    transform: translateY(-185px);
    display: none;
  }

  .atelier-open .atelier-container {
    padding: 10px 35px 10px 35px;
  }

  .mobile-open .atelier-container {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .atelier-container h1 {
    margin-bottom: 0;
    font-size: 16px;
  }

  .top-bar .politics-title {
    font-size: 16px;
  }
}

/*-----------------------------TIMELINE--------------------------*/

.timeline-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
}

.timeline .timeline-container {
  display: flex;
  padding: 175px 50px 50px;
}

.timeline .timeline-container .left-container {
  width: 75%;
}

.timeline .timeline-container .right-container {
  width: 60%;
  padding: 20px 0 0 100px;
}

.timeline .left-container .page-title {
  font-size: 36px;
}

.timeline .left-container .intro {
  font-family: 'Lato', Arial Bold, Gadget, sans-serif;
  font-size: 14px;
  line-height: 1.75;
  margin-bottom: 75px;
  max-width: 700px;
}

.timeline .left-container .timeline-projects {
  display: flex;
  flex-direction: column;
  margin-left: 35px;
}

.timeline .right-container .timeline-item-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
}

.timeline .timeline-item-content img {
  width: 100%;
  margin-bottom: 15px;
}

.timeline .timeline-link {
  min-height: 50px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  /*border-bottom: 1px solid #0000003b;*/
  -webkit-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  max-width: 750px;
}

.timeline .timeline-link:hover {
  color: #000;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0.01) 12.5%,
    rgba(0, 0, 0, 0.02) 25%,
    rgba(0, 0, 0, 0.015) 50%,
    rgba(0, 0, 0, 0.01) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

.timeline .timeline-link .timeline-data {
  width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 15px 15px 15px 0;
}

.timeline .timeline-link .location {
  transform: translateX(30px);
  opacity: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 10px;
  text-align: right;
}

.timeline .timeline-link .location span {
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
}

.timeline .timeline_project {
  margin-left: 0;
  transform: translate(50px);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0 5px 15px;
  -webkit-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
}

.timeline .timeline-link:hover .timeline_project {
  margin-left: 5px;
}

.timeline .timeline-link .title {
  display: flex;
  align-items: center;
}

.timeline .timeline-link .title h5 {
  margin: 0 25px 0 0;
}

.timeline .timeline-link .title .year {
  font-size: 12px;
  line-height: normal;
  opacity: 0.6;
}

.timeline .timeline-link .title,
.timeline .timeline-link .title h5 {
  font-size: 14px;
}

.timeline .timeline-link:hover .title {
  color: #cc2936;
}

.timeline .timeline-link .subtitle {
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.timeline .overlay_img_container {
  position: fixed;
  right: 100px;
  top: 50%;
  width: 45%;
  pointer-events: none;
  transform: translateY(-50%);
}

.timeline .timeline-link .block-anim{
  visibility: hidden;
  position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 9;
    -webkit-transition: 0;
  -moz-transition: 0;
  -o-transition: 0;
  transition: 0;
}

.timeline .timeline-link:hover .block-anim,
.timeline .timeline-link.clicked .block-anim{
  visibility: visible;
    width: 0;
    -webkit-transition: 400ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -moz-transition: 400ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    -o-transition: 400ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
    transition: 400ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
}

.timeline .timeline-link img {
  width: 100%;
  max-height: 70vh;
  opacity: 0;
  transform: translateX(30px);
  -webkit-transition: 0;
  -moz-transition: 0;
  -o-transition: 0;
  transition: 0;
}

.timeline .timeline-link:hover img {
  opacity: 1;
  transform: translateX(0);
  -webkit-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 600ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
}

.timeline .timeline_cross svg {
  opacity: 0;
  transform: translateX(30px) rotate(130deg);
  -webkit-transition: 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.timeline .timeline-link:hover .timeline_cross svg,
.timeline .timeline-link.clicked .timeline_cross svg {
  transform: rotate(0deg);
}

.timeline .timeline_cross svg line {
  stroke: #000;
}

.timeline .timeline-link:hover .timeline_cross svg line,
.timeline .timeline-link.clicked .timeline_cross svg line {
  stroke: #cc2936;
}

.timeline .timeline-link.clicked {
  color: #cc2936;
}

.timeline .timeline-link.clicked img {
  -webkit-transition: 1500ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 600ms;
  -moz-transition: 1500ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 600ms;
  -o-transition: 1500ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 600ms;
  transition: 1500ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 600ms;
  opacity: 1 !important;
  transform: translateX(-300px);
}

.timeline .timeline-link .overlay_img_container {
  -webkit-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 900ms;
  -moz-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 900ms;
  -o-transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 900ms;
  transition: 300ms cubic-bezier(0.9, 0.135, 0.15, 0.86) 900ms;
}

.timeline .noLink {
  pointer-events: none;
}

@media only screen and (max-width: 1650px){
}

@media only screen and (max-width: 1600px) {
  .timeline .timeline-container {
    display: flex;
    padding: 175px 50px 50px 0;
  }
}

@media only screen and (max-width: 1536px){
  .timeline .overlay_img_container {
    width: 40%;
  }
}

@media only screen and (max-width: 1440px){
}

@media only screen and (max-width: 1366px) {
  .timeline .overlay_img_container {
    top: calc(50% + 100px);
    transform: translateY(-50%);
  }

  .timeline .timeline-container {
    display: flex;
    padding: 150px 50px 50px 0;
  }
}

@media only screen and (max-width: 1280px) {
  .timeline .overlay_img_container{
    display: none
  }
}

@media only screen and (max-width: 1100px) {
  .timeline .timeline-link .timeline-data {
    min-width: 250px;
    max-width: 250px;
  }

  .timeline .timeline-container .left-container {
    width: 100%;
  }
  /*
  .timeline .overlay_img_container {
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }
  */

  .timeline .timeline-link img {
    margin-left: auto;
  }
}

@media only screen and (max-width: 800px) {
  .timeline .timeline-link .timeline-data {
    min-width: 200px;
    max-width: 200px;
  }
  /*
  .timeline .overlay_img_container img{
    transform:none!important;
  }
  */
}

@media only screen and (max-width: 720px) {
  .timeline .timeline_project {
    min-height: 95px;
  }

  .timeline .timeline-link .timeline-data {
    min-width: 175px;
    max-width: 175px;
  }
  /*
  .timeline .overlay_img_container {
    width: 85%;
    padding: 35px;
  }
  */
}

@media only screen and (max-width: 601px) {
  .timeline .timeline-link .title .year {
    min-width: 80px;
  }

  .timeline .timeline-link .title {
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .timeline .timeline-link .title h5 {
    width: 100%;
    line-height: 1;
  }

  .timeline .timeline-container {
    padding: 135px 50px 50px 0;
  }
}

@media only screen and (max-width: 480px) {
  .timeline .left-container .timeline-projects {
    margin-left: 15px;
  }

  .timeline .timeline-link .timeline-data {
    min-width: 120px;
    max-width: 120px;
  }

  .timeline .timeline-link .title .year {
    opacity: 0.5;
  }
  /*
  .timeline .overlay_img_container {
    width: 85%;
    padding: 0;
  }
  */
}

@media only screen and (max-width: 414px) {
  .timeline .timeline-link .timeline-data {
    min-width: 100px;
    max-width: 100px;
  }

  .timeline .timeline-container {
    padding: 150px 35px 50px 0;
  }

  .timeline .timeline-link .subtitle {
    font-size: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .timeline .timeline-container {
    padding: 135px 15px 50px 0;
  }

  .timeline .timeline-link .timeline-data {
    min-width: 85px;
    max-width: 85px;
  }

  .timeline .timeline-link .timeline-data {
    padding: 15px 10px 15px 0;
  }

  .timeline .timeline_project {
    padding: 15px 0 15px 10px;
  }

  .timeline .left-container .timeline-projects {
    margin-left: 15px;
  }
}

@media only screen and (max-height: 550px) {
  .timeline .timeline-container {
    padding: 120px 50px 50px 0;
  }
}

/*------------------ERROR PAGE---------------------*/

.erropage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.erropage h1 {
  font-size: 20px;
}

/*--------------------COOKIES BAR--------------------*/

#cookie-bar{
  /*display: flex!important;
    justify-content: center;
    height: 50px;
    align-items: center;*/
    background: #fff!important;
    color: #000!important;
    border-top: 1px solid #000;
    padding: 10px 35px!important;
}

#cookie-bar p{
  color: #000!important;
  font-family: "Lato", sans-serif!important;
  font-weight: 500;
  margin-right: 50px!important;
}

#cookie-bar #cookie-bar-button{
  border-radius: 0;
    box-shadow: none;
    border: 2px solid #000;
    background: #fff;
    color: #000!important;
    font-family: "Lato", sans-serif;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: 150ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -moz-transition: 150ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  -o-transition: 150ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  transition: 150ms cubic-bezier(0.9, 0.135, 0.15, 0.86);
  text-align: center;
  float: left;
}

#cookie-bar #cookie-bar-button:hover{
  background: #000;
    color: #fff!important;
    text-decoration: none;
}

@media only screen and (max-height: 991px){
  #cookie-bar p{
    margin-bottom: 10px;
  }
}

@media only screen and (max-height: 550px){
  #cookie-bar{
    padding: 10px 10px;
  }
  
  #cookie-bar p{
    font-size: 12px;
    margin-right: 0!important;
    margin-left: 0!important;
  }
}